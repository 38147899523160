import React from 'react';

const SudokuHistoryPage = () => {
    const funFacts = [
        {
            title: 'Origin of Sudoku',
            description:
                'Although Sudoku is popular today, it was not invented in Japan. The modern version of Sudoku was first published by an American architect, Howard Garns, in 1979 under the name "Number Place." It became widely popular in Japan in the mid-1980s.',
        },
        {
            title: 'Meaning of "Sudoku"',
            description:
                'The word "Sudoku" is short for a Japanese phrase "Sūji wa dokushin ni kagiru," which means "the numbers must remain single." This reflects the goal of the puzzle, where each number can appear only once in a row, column, and grid.',
        },
        {
            title: 'Global Popularity',
            description:
                'Sudoku gained global popularity in 2004 when The Times newspaper in London published a daily Sudoku puzzle, igniting a craze that spread worldwide.',
        },
        {
            title: 'Different Variants',
            description:
                'Apart from the traditional 9x9 grid, there are many Sudoku variants. These include larger grids (12x12, 16x16), diagonal Sudoku, and even multi-dimensional Sudoku puzzles.',
        },
        {
            title: 'Mathematical Background',
            description:
                'Sudoku puzzles are based on Latin squares, a mathematical structure studied by the famous mathematician Leonhard Euler. However, the additional constraints of Sudoku make it more complex and challenging.',
        },
    ];

    return (
        <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
            <h1>Sudoku: History and Fun Facts</h1>
            <section>
                <h2>History of Sudoku</h2>
                <p>
                    Sudoku, as we know it today, is a logic-based number puzzle. Though often
                    associated with Japan, it was actually first created by Howard Garns, an American
                    architect. The puzzle was initially published under the name "Number Place" in 1979
                    in a magazine called "Dell Pencil Puzzles & Word Games." In 1984, it was introduced to
                    Japan by Nikoli, a puzzle publishing company, under the name "Sudoku," and it quickly
                    gained a huge following.
                </p>
                <p>
                    The game reached international fame in 2004 when a New Zealand-based puzzle maker,
                    Wayne Gould, convinced The Times newspaper in London to publish daily Sudoku puzzles.
                    Since then, it has become a global phenomenon, challenging millions of people every day.
                </p>
            </section>

            <section style={{ marginTop: '20px' }}>
                <h2>Fun Facts and Curiosities</h2>
                <ul>
                    {funFacts.map((fact, index) => (
                        <li key={index} style={{ marginBottom: '10px' }}>
                            <strong>{fact.title}: </strong>{fact.description}
                        </li>
                    ))}
                </ul>
            </section>
        </div>
    );
};

export default SudokuHistoryPage;
