import React, {useEffect, useState} from 'react';
import ApiService from "../../services/apiService";
import SudokuBoard from "./SudokuBoard";
import SudokuControls from "./SudokuControls";
import {SudokuLevels} from "../../util/Constants";
import EndGame from "../../components/EndGame";

function SudokuGame() {

    const [gameState, setGameState] = useState({
        id: null,
        puzzle: [],
        board: [],
        details: {},
        size: 0,
        sqrSize: 0,
        username: null
    });
    let idleTimer = null;

    useEffect(() => {

        loadGame();

        return () => {
            document.removeEventListener('mousemove', resetIdleTimer);
            window.removeEventListener('beforeunload', handleBeforeUnload);
            clearTimeout(idleTimer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadGame = () => {
        const localGameState = JSON.parse(localStorage.getItem("sudokuGameState"));
        if (localGameState && localGameState.puzzle
            && localGameState.puzzle.length > 0
            && !localGameState.details.solved) {
            setGameStateFromData(localGameState);
            return;
        }

        onNewGame(localGameState?.puzzleLevel);
    }

    const setGameStateFromData = (loadedGameState) => {
        const board = loadedGameState.puzzle;
        if (board && board.length > 0) {
            loadedGameState.size = board.length;
            loadedGameState.sqrSize = Math.sqrt(board.length);
        }
        setGameState(loadedGameState);
    }

    const loadNewGame = async (level) => {
        try {
            const response = await ApiService.fetchNewGame(level, gameState.username);
            const game = response.data;

            let cluesCount = 0;
            const puzzle = [];
            for (let x = 0; x < game.puzzle.length; x++) {
                puzzle.push([]);
                for (let y = 0; y < game.puzzle.length; y++) {
                    const number = game.puzzle[x][y];
                    const cell = {
                        number: number,
                        posX: x,
                        posY: y,
                        selected: false,
                        sectionSelected: false,
                        clue: number > 0,
                    }
                    cluesCount += number > 0 ? 1 : 0;
                    puzzle.at(x).push(cell);
                }
            }
            game.puzzle = puzzle;
            game.details = {
                numberOfClues: cluesCount,
                missingNumbers: (puzzle.length * puzzle.length) - cluesCount,
                score: 0,
                mistakesCount: 0,
                maxMistakesAllowed: 3,
                end: false,
                endMessageShown: false,
                disabled: false,
                level: level
            }

            return game;
        } catch (error) {
            console.error('There was an error fetching the Sudoku board:', error);
            throw error;
        }
    }

    const onNewGame = (level = SudokuLevels.EASY) => {
        loadNewGame(level)
            .then(game => {
                setGameStateFromData(game);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const saveGameState = () => {
        localStorage.setItem('sudokuGameState', JSON.stringify(gameState));
    };

    const onValueChange = (newValue) => {
        const selectedCell = getSelectedCell();
        if (!selectedCell || selectedCell.clue || isNaN(newValue) || newValue < 1 || newValue > 9 || selectedCell.disabled) {
            return;
        }

        const updatedSelectedCell = {...selectedCell};
        updatedSelectedCell.number = newValue;

        gameState.puzzle[selectedCell.posX][selectedCell.posY] = updatedSelectedCell;

        updateDetails(gameState, updatedSelectedCell);

        if (isGameOver(gameState)) {
            endGame();
            showEndGameModal();
        }

        highlightCellAndSections(selectedCell, true);
        updateSelectedAndBoard(updatedSelectedCell);
        onSelectedCell(updatedSelectedCell);
    }

    const updateDetails = (game, updatedSelectedCell) => {
        const isValid = isCellValid(updatedSelectedCell);
        const details = game.details;
        if (!isValid) {
            details.mistakesCount++;
        } else {
            details.missingNumbers--;
        }
        game.details.disabled = isGameOver(game);
    }

    const isGameOver = (game) => {
        return isBoardSuccessfulCompleted(game) || game.details.mistakesCount > game.details.maxMistakesAllowed;
    }

    const isCellValid = (cell) => {
        return cell.number === gameState.board[cell.posX][cell.posY];
    }

    const onSelectedCell = (cell) => {

        const selectedCell = getSelectedCell();
        if (selectedCell) {
            highlightCellAndSections(selectedCell, false);
        }

        highlightCellAndSections(cell, true);
        updateSelectedAndBoard(cell);
    }

    const highlightCellAndSections = (cell, selected) => {
        cleanUpSelection();
        const posX = cell.posX;
        const posY = cell.posY;
        cell.selected = selected;

        gameState.puzzle[posX].forEach(cell => cell.sectionSelected = selected);
        gameState.puzzle.forEach(row => {
            row[posY].sectionSelected = selected;
        });

        if (cell.clue || cell.number !== 0) {
            gameState.puzzle.forEach(row => row.forEach(col => {
                if ((col.clue || col.number !== 0) && col.number === cell.number) {
                    col.sectionSelected = selected;
                }
            }));
        }

        let startRow = posX - posX % gameState.sqrSize;
        let startCol = posY - posY % gameState.sqrSize;

        for (let i = startRow; i < startRow + gameState.sqrSize; i++) {
            for (let j = startCol; j < startCol + gameState.sqrSize; j++) {
                gameState.puzzle[i][j].sectionSelected = selected;
            }
        }
    }

    const cleanUpSelection = () => {
        gameState.puzzle.forEach(row => row.forEach(col => {
            col.sectionSelected = false;
            col.selected = false;
        }));
    }

    const getSelectedCell = () => {
        for (let x = 0; x < gameState.puzzle.length; x++) {
            for (let y = 0; y < gameState.puzzle.length; y++) {
                const selectedCell = gameState.puzzle[x][y];
                if (selectedCell.selected) {
                    return selectedCell;
                }
            }
        }
        return null;
    }

    const resetIdleTimer = () => {
        clearTimeout(idleTimer);
        idleTimer = setTimeout(() => {
            saveGameState();
        }, 30000); // 1 minute
    };

    const handleBeforeUnload = () => {
        saveGameState();
    };

    const clearSelectedCell = () => {
        const selectedCell = getSelectedCell();
        if (selectedCell && !selectedCell.clue && !isCellValid(selectedCell)) {
            const updatedSelectedCell = {...selectedCell, number: 0};
            updateSelectedAndBoard(updatedSelectedCell);
        }
    }

    const updateSelectedAndBoard = (cell) => {
        if (!gameState) return;
        // Create a deep copy of the board
        const updatedBoard = gameState.puzzle.map(row => [...row]);

        // Update the cell within the deep copy
        updatedBoard[cell.posX][cell.posY] = {...cell};

        // Update the state with the new board, triggering a re-render
        setGameState({...gameState, puzzle: updatedBoard});
    }

    const isNumberComplete = (number) => {
        const board = gameState.puzzle;

        let count = 0;
        board.forEach(row => {
            row.forEach(cell => {
                if (cell.number === number && isCellValid(cell)) {
                    count++;
                }
            })
        });
        return count === gameState.size;
    }

    const endGame = async () => {
        await ApiService.endGame({
            puzzle: gameState.id,
            username: gameState.username,
            duration: 999,
            mistakesCount: gameState.details.mistakesCount,
            completedPuzzle: convertPuzzleToServerFormat(),
            solved: isBoardSuccessfulCompleted(gameState)
        }).then((response) => {
            console.log(`Saved result: ${JSON.stringify(response)}`);
        }).catch((error) => {
            console.log(`Error: ${JSON.stringify(error)}`);
        });
    }

    const convertPuzzleToServerFormat = () => {
        let completedBoard = [];
        for (let x = 0; x < gameState.puzzle.length; x++) {
            completedBoard.push([]);
            for (let y = 0; y < gameState.puzzle.length; y++) {
                completedBoard[x][y] = gameState.puzzle[x][y].number;
            }
        }
        return completedBoard;
    }

    const showEndGameModal = () => {
        const details = gameState.details;
        if (!details.end) {
            details.end = true;
        }
    }

    const closeEndGameModal = () => {
        const details = {...gameState.details};
        details.endMessageShown = true;
        setGameState({...gameState, details: details});
    }

    const isBoardSuccessfulCompleted = (game) => {
        const puzzle = game.puzzle;
        for (let x = 0; x < puzzle.length; x++) {
            for (let y = 0; y < puzzle.length; y++) {
                if (!isCellValid(puzzle[x][y])) {
                    return false;
                }
            }
        }
        return true;
    }

    document.addEventListener('mousemove', resetIdleTimer);
    window.addEventListener('beforeunload', handleBeforeUnload);

    return (
        <div className="container">
            <div className="row justify-content-between">

                <div className="col-12 col-md-6 mt-2 mb-2 inside-game-center">
                    <SudokuBoard
                        puzzle={gameState.puzzle}
                        onClearCell={clearSelectedCell}
                        onHandleSelected={onSelectedCell}
                        onValueChange={onValueChange}
                        isNumberComplete={isNumberComplete}
                        disabled={gameState.details.end}
                        isCellValid={isCellValid}
                    />
                </div>
                <div className="col-12 col-md-6 mt-2 mb-2">
                    <SudokuControls
                        onClearCell={clearSelectedCell}
                        onNewGame={onNewGame}
                        onValueChange={onValueChange}
                        details={gameState.details}
                        isNumberComplete={isNumberComplete}
                        disabled={gameState.details.end}
                    />
                </div>
            </div>
            <EndGame
                show={gameState.details.end && !gameState.details.endMessageShown}
                success={isBoardSuccessfulCompleted(gameState)}
                handleClose={() => closeEndGameModal()} />
        </div>
    );
}

export default SudokuGame;